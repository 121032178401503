<template>
  <button
    @click="goTo"
    class="text-base transform rounded-md primary"
    :class="{
      primary,
      secondary,
      primarySoft,
      graySoft,
      flat,
      round,
      redOutline,
      outline,
      'p-4 sm:py-3 md:py-4': !round,
      '!p-2 text-sm': tight,
      'sm:text-2xl': !smallText,
      'font-semibold': semibold,
      'font-normal': !semibold,
    }"
    v-bind="$attrs"
  >
    <div class="flex items-center justify-center p-2">
      <base-icon
        v-if="icon && !icon.rightHandSide"
        :component="icon.component"
        :name="icon.name"
        :source="icon.source"
        :class="icon.htmlClass"
      />

      <slot
        :class="{
          '!ml-3': !!icon && !icon.rightHandSide,
          '!mr-3': !!icon && icon.rightHandSide,
        }"
      />
      <base-icon
        v-if="icon && icon.rightHandSide"
        :component="icon.component"
        :name="icon.name"
        :source="icon.source"
        :class="icon.htmlClass"
      />
    </div>
  </button>
</template>

<script setup lang="ts">
import { Component } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    to?: RouteLocationRaw
    icon?: {
      rightHandSide?: boolean
      name: string
      source: any
      htmlClass: string
      component: Component
    }
    // Variants
    primary?: boolean
    secondary?: boolean
    primarySoft?: boolean
    graySoft?: boolean
    flat?: boolean
    round?: boolean
    tight?: boolean
    smallText?: boolean
    semibold?: boolean
    redOutline?: boolean
    outline?: boolean
  }>(),
  {
    smallText: false,
    semibold: true,
  }
)

const router = useRouter()

const goTo = () => {
  if (props?.to) router.push(props.to)
}
</script>

<style lang="postcss" scoped>
.primary {
  @apply text-white bg-primary active:bg-primary-disabled;
}
.primary:disabled {
  @apply text-white bg-primary-disabled cursor-default;
}
.secondary {
  @apply text-primary active:bg-gray-200 bg-white;
}
.graySoft {
  @apply text-gray-800 bg-gray-200;
}
.secondary:disabled {
  @apply text-gray-600 bg-white border-gray-600 cursor-default;
}
.primarySoft {
  @apply text-primary bg-primary-light;
}
.round {
  @apply rounded-full;
}
.flat,
.flat:disabled {
  @apply px-0 text-black bg-transparent;
}
.redOutline {
  @apply text-error bg-transparent border !border-error active:bg-red-50;
}

.redOutline:disabled {
  @apply text-red-400 bg-red-50 border !border-error active:bg-red-50;
}
</style>
