import PropertiesRepository from '@/api/repositories/properties'

export const state = {
  properties: [],
}

export const getters = {
  properties(state) {
    return state.properties
  },
  property(_state, _getters, _rootState, rootGetters) {
    return rootGetters['auth/property']
  },
  currentPropertyHasSauna(_state, getters) {
    return ['SLSW', 'TP'].includes(getters['property']?.acronym)
  },
}

export const mutations = {
  SET_PROPERTIES(state, newProperties) {
    state.properties = newProperties
  },
}

export const actions = {
  async fetchProperties({ commit }) {
    const response = await PropertiesRepository.getProperties()
    const properties = response.data
    commit('SET_PROPERTIES', properties)
    return properties
  },
  async setProperty({ commit, getters }, { id }) {
    const property = getters.properties.find(p => p.id === id)
    commit('auth/SET_PROPERTY', property, { root: true })
  },
}
